<template>
  <div>
    <div class="upload-wrapper">
      <div>
        <slot name="fileName"></slot>
      </div>
      <div class="d-flex">
        <el-button :disabled="loading" type="primary" @click="openFile">
          ファイルを指定してアップロード
        </el-button>
        <UploadLoading class="ml-1" v-if="loading" />
      </div>
      <input
        type="file"
        ref="fileInput"
        @change="onFileSelected"
        :accept="accept"
      />
    </div>
    <div v-if="message" class="el-form-item__error mt-4">{{ message }}</div>
  </div>
</template>
<script>
import UploadLoading from "@/components/UploadLoading";
import { api } from "@/api";
export default {
  name: "BaseUploadVideo",
  components: {
    UploadLoading,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "video/mp4",
    },
    limit: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: "image",
    },
    keys: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      maxDuration: 150.9,
      videoWidth: 0,
      videoHeight: 0,
      message: '',
    };
  },
  methods: {
    updatePath(value) {
      this.$emit("update-path", this.keys, value);
    },
    async handleUploadVideo(file, duration) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("video_mp4", file);

        const { data } = await api.uploadVideo(formData);

        this.updatePath({
          path: data.path,
          time: duration,
          fileName: file.name,
          videoWidth: this.videoWidth,
          videoHeight: this.videoHeight,
        });

        this.message = '';

        return false;
      } catch (error) {
        this.message = error.response.data.meta.errors.video_mp4[0]
      } finally {
        this.loading = false;
      }
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    async handleBeforeUpload(file) {
      const duration = await this.getVideoDuration(file);
      const size = this.checkVideoSize(file);

      if (duration && size) {
        await this.handleUploadVideo(file, duration);
      }

      return false;
    },
    async getVideoDuration(file) {
      return await new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        this.getResolution(video);

        video.onloadedmetadata = () => {
          const duration = video.duration;
          resolve(duration <= this.maxDuration ? duration : false);

          if (duration >= this.maxDuration) {
            this.$message.error("150秒より長い動画はアップロードできません。");
          }
        };

        video.onerror = function () {
          reject(false);
        };
      });
    },
    checkVideoSize(file) {
      const maxSizeBytes = 300 * 1024 * 1024;
      if (file.size > maxSizeBytes) {
        this.$message.error("300MBを超える動画はアップロードできません。");
      }

      return file.size < maxSizeBytes;
    },
    openFile() {
      this.$refs.fileInput.click();
    },
    onFileSelected(event) {
      this.handleBeforeUpload(event.target.files[0]);
    },
    getResolution(video) {
      let that = this;

      video.addEventListener("loadedmetadata", function () {
        that.videoWidth = this.videoWidth;
        that.videoHeight = this.videoHeight;
      });
    },
  },
};
</script>
<style scoped>
.upload-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>