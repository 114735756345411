<template>
  <div>
    <HEADER/>
    <MENU/>
    <div class="main">
      <h2 class="title">ポイント購入一覧</h2>
      <form class="">
        <div class="clear" style="display: inline-block">
          <div class="row-up-group">
            <div class="form-group fl mr20">
              <label>購入ID</label>
              <input
                  type="text"
                  class="form-control"
                  v-model.trim="formSearch.transaction_uid"
                  placeholder=""
              />
            </div>
            <div class="form-group fl mr20">
              <label>ユーザーID</label>
              <input
                  type="text"
                  class="form-control"
                  v-model.trim="formSearch.user_uid"
                  placeholder=""
              />
            </div>
            <div class="form-group fl mr20">
              <label>購入日</label>
              <div class="date-picker-w">
                <el-date-picker
                    v-model="formSearch.payment_at"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="開始日"
                    end-placeholder="終了日"
                    value-format="yyyy-MM-dd"
                    format="yyyy 年 M 月 d 日"
                    :editable="false"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="form-group fl mr20">
              <label>金額</label>
              <el-select
                  class="form-select"
                  v-model.trim="formSearch.amount"
                  placeholder=""
                  :clearable="true"
              >
                <el-option
                    v-for="item in amountOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-group fl mr20">
              <label>性別</label>
              <el-select
                  class="form-select"
                  v-model.trim="formSearch.gender"
                  placeholder=""
                  :clearable="true"
              >
                <el-option
                    v-for="item in genderOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-group fl mr20">
              <label>誕生日</label>
              <div class="date-picker-w">
                <el-date-picker
                    v-model="formSearch.date_of_birth"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="開始日"
                    end-placeholder="終了日"
                    value-format="yyyy-MM-dd"
                    format="yyyy 年 M 月 d 日"
                    :editable="false"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="row-down-group">

          </div>
        </div>
      </form>
      <div class="text-center">
        <button type="button" class="btn btn-red ml20" @click="search()">
          検索
        </button>
      </div>
      <el-table
          :header-cell-style="{
          background: '#efefef',
          color: '#212529',
          fontWeight: '500',
        }"
          :data="list"
          class="table table-bordered tablebox"
          style="font-size: 1.6rem"
          :cell-style="{ fontSize: '16px' }"
      >
        <el-table-column
            header-align="center"
            align="center"
            label="購入ID"
            width="150"
            prop="uid"
        />
        <el-table-column
            header-align="center"
            align="start"
            label="ユーザーID"
            width="auto"
        >
          <template #default="scope">
            <div class="link" @click="handleOpenDialog(scope.row.user.id)">
                {{ scope.row.user.uid }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            header-align="center"
            align="start"
            label="購入日時"
            width="auto"
            prop="payment_at"
        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="start"
            label="金額"
            width="auto"
            prop="amount"
        />
        <el-table-column
            header-align="center"
            align="start"
            label="性別"
            width="auto"
        >
          <template #default="scope">
            {{ getGenderName(scope.row.user.gender) }}
          </template>
        </el-table-column>
        <el-table-column
            header-align="center"
            align="start"
            label="誕生日"
            width="auto"
        >
          <template #default="scope">
            {{ scope.row.user.date_of_birth }}
          </template>
        </el-table-column>
      </el-table>
      <PAGE
          :current="page"
          :pages="pages"
          :size="per_page"
          :total="total"
          @changePage="changePage"
      />
    </div>

    <BaseDialog :visible.sync="visible" :close="handleCloseDialog">
      <UserDetail :id="userId" />
    </BaseDialog>
  </div>
</template>

<script>
import HEADER from "@/components/Header";
import MENU from "@/components/MENU";
import {api} from "@/api";
import PAGE from "@/components/page";
import BaseDialog from "@/components/BaseDialog";
import UserDetail from "@/components/transaction/UserDetail.vue";

export default {
  name: "transaction",
  components: {
    HEADER,
    MENU,
    PAGE,
    BaseDialog,
    UserDetail
  },
  created() {
    this.getListTransaction();
    this.getPointPackage();
  },
  data() {
    return {
      visible: false,
      userId: null,
      list: [],
      pages: 0,
      total: 0,
      page: 1,
      per_page: 15,
      formSearch: {
        transaction_uid: null,
        user_uid: null,
        payment_at:null,
        amount: null,
        date_of_birth:null,
        gender:null
      },
      genderOptions: [
        {id: 0, name: '男性'},
        {id: 1, name: '女性'},
        {id: 2, name: 'どちらでもない'}
      ],
      amountOptions: [
      ]
    };
  },
  methods: {
    async getListTransaction() {
      try {
        let params = {
          per_page: this.per_page,
          page: this.page,
          payment_at: this.formSearch.payment_at,
          user_uid: this.formSearch.user_uid,
          transaction_uid: this.formSearch.transaction_uid,
          amount: this.formSearch.amount,
          gender: this.formSearch.gender,
          date_of_birth: this.formSearch.date_of_birth
        };
        const res = await api.listTransaction(params);

        this.list = res.data;
        this.pages = res.meta.pagination.count;
        this.page = res.meta.pagination.current_page;
        this.total = res.meta.pagination.total;
        this.size = res.meta.pagination.per_page;
      } catch (e) {
        console.error("Error", e);
      }
    },

    async getPointPackage() {
      try {
        const res = await api.getAllPointPackage({});
        this.amountOptions = res.data.map((item) => {
          return {
            id: item.amount,
            name: item.amount,
          }
        });
      } catch (e) {
        console.error("Error", e);
      }
    },
    changePage(value) {
      this.page = value;
      this.getListTransaction();
    },
    getGenderName(gender) {
      if (gender === 0) {
        return '男性';
      }

      if (gender === 1) {
        return '女性';
      }

      return 'どちらでもない';
    },
    async search() {
      await this.getListTransaction();
    },

    handleOpenDialog(userId) {
      this.userId = userId;
      this.visible = true;
    },

    handleCloseDialog() {
      this.visible = false;
      this.userId = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}
.row-up-group,
.row-down-group {
  display: block !important;
}
.form-group {
  label {
    display: inline-block;
  }
  .form-select {
    display: block;
    font-size: 14px;
    background-color: #fff;
    border-radius: 4px;
  }
}
.date-picker-w .el-input__inner {
  width: 100%;
}
.link {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
</style>
