<template>
  <el-form-item class="mr20" :prop="keys">
    <label class="mb-0" for="number1">{{ label }}</label>
    <div style="display: block">
      <el-date-picker
        v-model="valueDate"
        class="w-100"
        :picker-options="pickerOptions"
        type="daterange"
        range-separator="~"
        start-placeholder="開始日"
        end-placeholder="終了日"
        value-format="yyyy-MM-dd"
        format="yyyy 年 MM 月 dd 日"
        clearable
      >
      </el-date-picker>
    </div>
  </el-form-item>
</template>

<script>
import moment from "moment";
export default {
  name: "BaseDatePicker",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      valueDate: this.value,
    };
  },
  watch: {
    valueDate(value) {
      const startDate = value && value.length ? `${value[0]} 00:00:00` : "";
      const endDate =
        value && value.length ? `${value[1]} ` + this.getTime(value[1]) : "";
      this.$emit("change-input", this.keys, {
        startDate: startDate,
        endDate: endDate,
      });
    },
    value(value) {
      this.valueDate = value;
    },
  },
  methods: {
    getTime(date) {
      const currentDate = moment(date, "YYYY-MM-DD");

      if (!currentDate.isSame(moment(), "day")) {
        currentDate.set({ hour: 23, minute: 59, second: 59 });
        return currentDate.format("HH:mm:ss");
      } else {
        return moment().format("HH:mm:ss");
      }
    },
  },
};
</script>
