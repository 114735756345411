import axios from "axios";

const $request = async (option) => {
    const request = axios.create({
        baseURL: process.env.VUE_APP_URL,
    });

    const token = localStorage.getItem("authToken");
    request.interceptors.request.use((config) => {
        if (token !== undefined) {
            config.headers["Authorization"] = token;
            config.headers["Content-Type"] = "application/json";
        }

        return config;
    });

    try {
        let response = await request({
            responseType: option.responseType,
            url: option.url,
            method: option.method || "GET",
            data: option.data,
            params: option.params,
        });
        return response.data;
    } catch (e) {
        const error = e.response || "";
        localStorage.removeItem("authToken");
        return error;
    }
};

export {$request};
