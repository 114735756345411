<template>
  <div>
    <HEADER />
    <MENU />
  </div>
</template>

<script>
import HEADER from "@/components/Header";
import MENU from "@/components/MENU";

export default {
  name: "home",
  components: {
    HEADER,
    MENU,
  }
};
</script>

<style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}
</style>
