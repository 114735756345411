import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        status: '',
        loading: false,
        admin: {},
    },

    mutations: {
        SET_STATUS: (state, status) => {
            state.status = status
        },
        SET_ADMIN:(state, admin) => {
            state.admin = admin
        },
        SET_LOADING: (state, status) => {
            state.loading = status
        },
    },
    actions: {
        setLoading: ({ commit }, status) => {
            commit('SET_LOADING', status)
        }
    },
    getters: {
        isLoading: state => state.loading
    }
})

export default store