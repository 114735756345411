<template>
  <BaseForm :data="data" :rules="rules" ref="form" :handleSubmit="handleSubmit">
    <table class="table table-bordered tablebox-t5 textarea-detile">
      <tbody>
        <tr>
          <th width="210">
            <div class="d-flex justify-content-between">
              <div>
                <span class="style_red">*</span>
                <span>エピソードID</span>
              </div>
            </div>
          </th>
          <td>
            <el-form-item label="" prop="id">
              <BaseInput
                :value="data.id"
                keys="id"
                type="number"
                :disabled="true"
              />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <th width="210">
            <div class="d-flex justify-content-between">
              <div>
                <span class="style_red">*</span>
                <span>エピソードNo.</span>
              </div>
            </div>
          </th>
          <td>
            <el-form-item prop="orderNo">
              <BaseInput
                :value="data.orderNo"
                keys="orderNo"
                type="number"
                @keypress.native="(event) => handleOnKeyPress(event)"
                @input-change="(name, value) => handleChange(name, value)"
              />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <th width="210">
            <div class="d-flex justify-content-between">
              <div>
                <span class="style_red">*</span>
                <span>エピソードタイトル</span>
              </div>
            </div>
          </th>
          <td>
            <el-form-item prop="title">
              <BaseInput
                :value="data.title"
                keys="title"
                type="text"
                @input-change="(name, value) => handleChange(name, value)"
              />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <th width="210">
            <div class="d-flex justify-content-between">
              <div>
                <span class="style_red">*</span>
                <span>ポイント設定</span>
              </div>
            </div>
          </th>
          <td>
            <el-form-item prop="points">
              <BaseInput
                :value="data.points"
                keys="points"
                type="number"
                @keypress.native="(event) => handleOnKeyPress(event)"
                @input-change="(name, value) => handleChange(name, value)"
              />
            </el-form-item>
          </td>
        </tr>
        <tr style="height: 100px">
          <th width="210">
            <div class="d-flex justify-content-between">
              <div>
                <span class="style_red">*</span>
                <span>動画ファイルアップロード</span>
              </div>
            </div>
          </th>
          <td>
            <el-form-item prop="video_mp4">
              <BaseUploadVideo
                accept="video/mp4"
                type="video"
                keys="video_mp4"
                @update-path="(name, value) => handleChange(name, value)"
              >
                <template v-slot:fileName>
                  <span>{{
                    data.video_mp4 && data.video_mp4.fileName
                      ? data.video_mp4.fileName
                      : "example.mp4"
                  }}</span>
                </template>
              </BaseUploadVideo>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <th width="210">
            <div class="d-flex justify-content-between">
              <div>
                <span class="style_red">*</span>
                <span>サムネイル画像（エピソード/他</span>
              </div>
            </div>
          </th>
          <td>
            <el-form-item prop="image">
              <div class="upload-file">
                <div>
                  W:{{ image.width }}px H:{{ image.height }}px（{{
                    type_file
                  }}）
                </div>
                <el-button type="primary" @click="openFile">
                  ファイルを指定してアップロード
                </el-button>
                <input
                  type="file"
                  class="d-none"
                  ref="imageInput"
                  accept="image/png, image/jpeg, image.jpg"
                  @change="onFileSelected"
                />
              </div>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <th width="210">
            <div class="d-flex justify-content-between">
              <div>
                <span class="style_red">*</span>
                <span>ステータス</span>
              </div>
            </div>
          </th>
          <td>
            <BaseRadio
              :value="data.isPublish"
              :options="statusOptions"
              keys="isPublish"
              @change-radio="(name, value) => handleOnChange(name, value)"
            />
          </td>
        </tr>
        <tr v-if="data.id">
          <th width="210">※削除</th>
          <td>
            <el-form-item prop="isDeleted">
              <BaseCheckbox
                :options="deleteOptions"
                :checked="data.isDeleted"
                keys="isDeleted"
                @change-checkbox="(name, value) => handleOnChange(name, value)"
              >
              </BaseCheckbox>
            </el-form-item>
          </td>
        </tr>
      </tbody>
    </table>
  </BaseForm>
</template>
<script>
import BaseForm from "@/components/BaseForm";
import BaseInput from "@/components/BaseInput";
import BaseUploadVideo from "@/components/BaseUploadVideo";
import BaseRadio from "@/components/BaseRadio";
import BaseCheckbox from "@/components/BaseCheckbox";
import { PUBLISHING_STATUS, DELETE_STATUS } from "@/constants";

export default {
  name: "Form",
  components: {
    BaseForm,
    BaseInput,
    BaseUploadVideo,
    BaseRadio,
    BaseCheckbox,
    PUBLISHING_STATUS,
    DELETE_STATUS,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    handleOnChange: {
      type: Function,
      default: () => {},
    },
    handleSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      rules: {
        orderNo: [
          { required: true, message: "エピソードNoは必須フィールドです" },
        ],
        title: [{ required: true, message: "タイトルは必須フィールドです" }],
        points: [{ required: true, message: "ポイントは必須フィールドです" }],
        video_mp4: [{ required: true, message: "ビデオは必須フィールドです" }],
        image: [{ required: true, message: "画像は必須フィールドです" }],
      },
      characterNotEnter: ["-", "e", "+", ".", ","],
      statusOptions: PUBLISHING_STATUS,
      deleteOptions: DELETE_STATUS,
      image: {
        width: 0,
        height: 0,
      },
      type_file: "jpg, png, jpeg",
    };
  },

  watch: {
    data: {
      handler: function (val) {
        if (val && val.image && val.image.width && val.image.height) {
          this.image.width = val.image.width;
          this.image.height = val.image.height;
          this.type_file = this.getTypeFile(val.image.image_url);
        }

        if (val.points) {
          val.points = this.convertNumber(val.points);
        }

        if (val.orderNo) {
          val.orderNo = this.convertNumber(val.orderNo);
        }
      },
      deep: true,
    },
  },

  methods: {
    getDimensions(file) {
      const image = document.createElement("img");
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        this.image.width = image.width;
        this.image.height = image.height;
        this.type_file = file.name.split(".").pop();
      };
    },
    beforeUpload(file) {
      this.handleChange("image", { file: file });
      this.getDimensions(file);

      return true;
    },
    handleOnKeyPress(event) {
      if (this.characterNotEnter.includes(event.key)) event.preventDefault();
    },
    handleChange(name, value) {
      this.$refs.form.$refs.data.clearValidate(name);
      this.handleOnChange(name, value);
    },
    getTypeFile(src) {
      return src.split(".").pop();
    },
    convertNumber(number) {
      number =
        typeof number === "string"
          ? number === "0"
            ? 0
            : parseInt(number, 10)
          : number;

      return number <= 0 ? 0 : number;
    },
    openFile() {
      this.$refs.imageInput.click();
    },
    onFileSelected(event) {
      this.beforeUpload(event.target.files[0]);
    },
  },
};
</script>

<style scoped lang="scss">
.upload-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnbox {
  width: 100%;
}
</style>