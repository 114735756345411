<template>
    <el-form :model="data" :rules="rules" v-bind="$attrs" ref="data">
      <slot></slot>
        <el-form-item class="text-center">
            <el-button type="primary" :disabled="isLoading" @click="submitForm">
                更新する
            </el-button>
        </el-form-item>
    </el-form>
  </template>
  
  <script>
  export default {
    name: "BaseForm",
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      rules: {
        type: Object,
        default: () => ({}),
      },
      handleSubmit: {
        type: Function,
        default: () => {},
      }
    },
    computed: {
      isLoading() {
        return this.$store.getters.isLoading
      }
    },
    methods: {
      submitForm() {
        this.$refs['data'].validate(async (valid) => {
          if (valid) {
            await this.handleSubmit();
          } else {
            console.log('error submit!!');
            return false;
          }
        
        })
      }
    }
  };
  </script>
  