import router from "../router";
class auth {
  constructor() {
    this.storeName = ""; // ec/mo/sr
    this.picture = "";
  }

  logout() {
    localStorage.clear();
    router.push({ path: "/" });
  }
}
export default auth;
