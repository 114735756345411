<template>
  <el-form-item label="" :prop="keys">
    <label class="mb-0" for="number1">{{ label }}</label>
    <el-input
      :value="value"
      :type="type"
      :placeholder="placeholder"
      @input="inputChange"
      v-bind="$attrs"
    />
  </el-form-item>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    keys: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: ""
    }
  },
  methods: {
    inputChange(value) {
      this.$emit("input-change", this.keys, value);
    }
  }
};
</script>
