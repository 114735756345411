<template>
  <div>
    <div class="login">
      <div class="login-ttl"><img src="@/assets/image/login.png" alt="" /></div>
      <form action="">
        <input
          type="text"
          v-model="username"
          class="form-control"
          placeholder="ユーザーID"
        />
        <div class="password-container">
          <input
            :type="typeInput"
            v-model="password"
            class="form-control"
            placeholder="パスワード"
          />
          <div @click="toggle" class="toggle">
            <img v-if="showPassword" class="icon" src="@/assets/image/eye.svg" />
            <img v-else class="icon" src="@/assets/image/eye-off.svg" />
          </div>
        </div>
        <button class="btn btn-red" @click="login" :disabled="isDisable">
          ログイン
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      username: "",
      password: "",
      isDisable: false,
      showPassword: false
    };
  },
  created() {
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
  },
  computed: {
    loginUrl: function () {
      return `${process.env.VUE_APP_URL}/login`;
    },
    profileUrl: function () {
      return `${process.env.VUE_APP_URL}/profile`;
    },

    typeInput: function () {
      return this.showPassword ? 'text' : 'password' 
    }
  },
  methods: {
    login: function () {
      this.isDisable = true;

      this.$post(this.loginUrl, {
        username: this.username,
        password: this.password,
      })
        .then((response) => {
          if (response.meta.code === 200) {
            localStorage.setItem("authToken", "Bearer " + response.data.token);
            this.$setAuthToken(response.data.token);
            this.getProfile();
            this.$router.push({ name: "home" });
          } else {
            this.isDisable = false;
          }
        })
        .catch(() => {
          this.$message.error(
            "Invalid username or password. Please try again."
          );
          this.isDisable = false;
        });
    },
    getProfile: function () {
      this.$get(this.profileUrl, {})
        .then((res) => {
          this.$store.commit("SET_ADMIN", res.data);
        })
        .catch(() => {
          this.isDisable = false;
        });
    },

    toggle() {
      this.showPassword = !this.showPassword
    }
  },
};
</script>

<style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}

.password-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 28px;
  margin-bottom: 28px;

  .toggle {
    display: flex;
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    margin-right: 10px;

    .icon {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1060px) {
  .form-control {
    max-width: none;
  }
  .btn {
    height: 50px;
  }
}
</style>
