<template>
	<el-checkbox-group :value="checked" @input="changeCheckbox">
			<el-checkbox v-for="option in options" :key="option.value" :label="option.value">
					{{ option.label }}
			</el-checkbox>
	</el-checkbox-group>
</template>
<script>
	export default {
		name: "BaseCheckbox",
		props: {
			options: {
				type: Array,
				default: () => []
			},
			checked: {
				type: Array | Boolean,
				default: () => []
			},
			keys: {
				type: String,
				default: ""
			}
		},
		methods: {
			changeCheckbox(value) {
				this.$emit("change-checkbox", this.keys, value);
			},
		},
	}; 
</script>