<template>
  <div v-if="total > 0">
    <div class="text-center mt70" v-if="totalpages == 1">{{ total }}件</div>
    <div class="text-center mt70" v-if="totalpages != 1 && total != 0">
      {{ total }}件中 {{ (current - 1) * size + 1 }}-{{
        current != totalpages
          ? current * size
          : (current - 1) * size + (total % size)
      }}件
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="current"
      :page-size="size"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "PAGE",
  props: {
    current: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 20,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      totalpages: this.pages,
      pageCurrent: this.current,
      pagesize: this.size,
      pagetotal: this.total,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init: function () {
      this.totalpages = this.pages;
      this.pageCurrent = this.current;
      this.pagesize = this.size;
      this.pagetotal = this.total;
    },
    pageClick: function (n) {
      this.pageCurrent = n;
      this.$emit("pageUp", this.pageCurrent);
    },
    handleCurrentChange(value) {
      this.$emit("changePage", value);
    },
  },
  watch: {
    pages: function () {
      this.init();
    },
    current: function () {
      this.init();
    },
  },
};
</script>
<style lang="scss">
.el-pagination.is-background {
  text-align: center;
  margin-top: 5px;
  // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  button.btn-prev,
  button.btn-next {
    min-width: 35px;
    height: 38px;
    line-height: 34px;
    i {
      font-size: 16px;
    }
  }
  button:not(:disabled):hover {
    background-color: #e7425b;
    color: #333 !important;
  }
  ul.el-pager {
    // li {
    // background-color: #e7425b !important;
    // margin: 0 1px;
    // }
    li.active {
      background-color: #e7425b !important;
      color: #ffffff !important;
    }
    li {
      border: 1px solid #dddddd;
      font-size: 17px;
      min-width: 38px;
      height: 38px;
      line-height: 34px;
      color: rgb(44, 41, 41) !important;
    }
    li:hover {
      background-color: #e7425b;
      color: #333 !important;
    }
  }
}
</style>
