<template>
  <div>
    <HEADER />
    <MENU />
    <div class="main">
        <Form :data="formData" :handleOnChange="handleOnChange" :handleSubmit="handleSubmit"></Form>
    </div>
  </div>
</template>
<script>
    import HEADER from '@/components/Header.vue';
    import MENU from '@/components/MENU.vue';
    import Form from '@/components/episode/Form.vue';
    import { api } from "@/api";

    export default {
      name: "EpisodeStore",
      components: {
        HEADER,
        MENU,
        Form
      },
      data() {
        return {
          id: this.$router.history.current.params.id,
          formData: {
            id: null,
            orderNo: null,
            title: "",
            points: null,
            video_mp4: null,
            image: null,
            isPublish: 1,
          }
        };
      },

      methods: {
        handleOnChange(key, value) {
          this.formData[key] = value;
        },

        async handleSubmit() {
          try {
            this.$store.dispatch('setLoading', true)

            const data = new FormData();
            data.append("drama_id", this.id)
            data.append("order_no", this.formData.orderNo);
            data.append("title", this.formData.title);
            data.append("points", this.formData.points);
            data.append("video_mp4", this.formData.video_mp4.path);
            data.append("image", this.formData.image.file);
            data.append("time", Math.floor(this.formData.video_mp4.time));
            data.append("width_video", this.formData.video_mp4.videoWidth);
            data.append("height_video", this.formData.video_mp4.videoHeight);
            data.append("is_publish", this.formData.isPublish);

            await api.storeEpisode(data);
            this.$router.push({ name: "drama-show", params: { id: this.id } });
          } catch (error) {
            this.$message.error(error.response.data.meta.message);
          } finally {
            this.$store.dispatch('setLoading', false)
          }
        }
      },
    };
</script>