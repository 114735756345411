import moment from "moment"
class common {

  constructor() {
    this.menu = '' // ec/mo/sr
    this.appDisplayed = false // true: a role judgement process is already completed and the page in app is already displayed or the page in app is now loading.
  }

  changeLanguage(i18n, lang) {
    i18n.locale = lang
    localStorage.setItem('localLanguages', lang)
  }

  getQueryParams(obj) {
    const params = new URLSearchParams(obj);

    return params.toString();
  }

  getFileNameFromPath(path) {
    return path.split('/').pop()
  }

  useDownloadCSV() {

    const makeCSV = (data) => {
      const csv = []
      const headers = ["Transaction ID", "User ID", "payment at", "amount"]
      csv.push(headers.join(","))
  
      data.forEach(row => {
        const values = [row.uid, row.user.uid, row.payment_at, row.amount]
        csv.push(values.join(","))
      })
  
      return csv.join('\n') 
  
    }
  
    const downloadCSVFile = (data) => {
      const date = moment().format("YYYY/MM/DD");
      const fileName = `transactions_${date}.csv`
      const blob = new Blob([data], { type: 'text/csv' }); 
      const url = window.URL.createObjectURL(blob) 
      const a = document.createElement('a') 
      a.setAttribute('href', url) 
      a.setAttribute('download', fileName);  
      a.click() 
    }

    return { makeCSV, downloadCSVFile }
  }
}

export default common
