<template>
  <div id="header">
    <h1 class="logo_manage"><img src="@/assets/image/logo.png" alt="Manage" width="169" height="39"></h1>
    <div class="fr">
      <div class="box log-out" @click.prevent="logout">
        <div class="circle"><span class="char-in-circle-power"><font-awesome-icon icon="power-off"/></span></div>
        <div>ログアウト</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      storeName: localStorage.getItem("storeName"),
      storeImg: localStorage.getItem("storeImg"),
    };
  },
  computed: {
    url: function () {
      return `${process.env.VUE_APP_URL}/logout`;
    },
  },
  methods: {
    logout() {
      this.$post(this.url, {})
        .then(() => {
          this.$auth.logout();
          return this.$router.push({ path: "/" });
        })
        .catch((err) => {
          this.$message.error(err.response.data.meta.message);
        });
    },
  },
};
</script>
