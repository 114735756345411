import { post, get, put } from "@/utils/http";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const api = {
  // DRAMA
  listDrama: (params) => get("/dramas", params),
  showDrama: (id) => get(`/dramas/${id}`),

  // TRANSACTION
  listTransaction: (params) => get("/transactions", params),
  listTransactionExport: (params) => get("/transactions/export", params),

  // POINT PACKAGE
  listPointPackage: (params) => get("/point-packages", params),
  getAllPointPackage: (params) => get("/point-packages/get-all", params),
  storePointPackage: (params) => post("/point-packages", params),
  showPointPackage: (id) => get(`/point-packages/${id}`),
  updatePointPackage: (id, params) => put(`/point-packages/${id}`, params),

  // EPISODE
  storeEpisode: (body) => post("/episodes", body, config),
  getEpisode: (id) => get(`/episodes/${id}`),
  updateEpisode: (id, body) => post(`/episodes/${id}`, body, config),
  checkConvert: (params) => get("/video/upload/check-convert", params),

  // USER

  getDetailUser: (id) => get(`/users/${id}`),

  // UPLOAD
  uploadVideo: (body) => post("/video/upload", body, config),

  // COMMENT
  listComment: (params) => get("/comments", params),
  showComment: (id) => get(`/comments/${id}`),
  updateComment: (id, params) => put(`/comments/${id}`, params),

  // USER
  totalUser: (params) => get("/user/total", params),
};
