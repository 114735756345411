<template>
    <div>
      <HEADER />
      <MENU />
      <div class="main">
          <Form :data="formData" :handleOnChange="handleOnChange" :handleSubmit="handleSubmit"></Form>
      </div>
    </div>
</template>
<script>
    import HEADER from '@/components/Header.vue';
    import MENU from '@/components/MENU.vue';
    import Form from '@/components/episode/Form.vue';
    import { api } from "@/api";

    export default {
        name: "EpisodeStore",
        components: {
            HEADER,
            MENU,
            Form
        },
        data() {
            return {
            id: this.$router.history.current.params.id,
            episodeId: this.$router.history.current.params.episodeId,
            formData: {
                id: null,
                orderNo: null,
                title: "",
                points: null,
                video: null,
                image: null,
                isPublish: 0,
                isDeleted: 0,
            }
            };
        },
        created() {
            this.getEpisode()
        },
        methods: {
            handleOnChange(key, value) {
                this.formData[key] = value;
            },

            async handleSubmit() {
                try {
                    this.$store.dispatch('setLoading', true)

                    const data = new FormData();
                    data.append("_method", "PUT");
                    data.append("drama_id", this.id)
                    data.append("order_no", this.formData.orderNo);
                    data.append("title", this.formData.title);
                    data.append("points", this.formData.points);
                    data.append("video_mp4", this.formData.video_mp4.path);
                    if (this.formData.image.file) {
                        data.append("image", this.formData.image.file);
                    }
                    data.append("time", Math.floor(this.formData.video_mp4.time));
                    data.append("width_video", this.formData.video_mp4.videoWidth);
                    data.append("height_video", this.formData.video_mp4.videoHeight);
                    data.append("is_publish", this.formData.isPublish);
                    data.append("is_delete", this.formData.isDeleted ? 1 : 0);

                    await api.updateEpisode(this.episodeId, data);
                    this.$router.push({ name: "drama-show", params: { id: this.id } });
                } catch (error) {
                  this.$message.error(error.response.data.meta.message);
                } finally {
                    this.$store.dispatch('setLoading', false)
                }
            },
            transformData(data, width, height) {
                return {
                    id: data.id,
                    orderNo: data.order_no,
                    title: data.title,
                    points: data.points,
                    video_mp4: {
                        path: data.video_mp4,
                        time: data.time,
                        fileName: this.$commonClass.getFileNameFromPath(data.video_mp4)
                    
                    },
                    image: {
                        file: null,
                        image_url: data.image_url,
                        width: width,
                        height: height
                    },
                    isPublish: data.is_publish ? 1 : 0,
                    isDeleted: !!data.deleted_at
                };
            },
            async getEpisode() {
                try {
                    const { data } = await api.getEpisode(this.episodeId)
                    const { width, height } = await this.getDimensions(data.image_url)

                    this.formData = this.transformData(data, width, height)
                } catch (error) {
                    console.log(error);
                }
            },

            async getDimensions(src) {
                try {
                    const img = new Image()
                    img.src = src

                    await img.decode()
                    return { width: img.width, height: img.height }
                } catch (error) {
                  return { width: 0, height: 0 }
                }
            }
        }
    };
</script>