export const PUBLISHING_STATUS = [
    {
        label: "公開",
        value: 1,
    },
    {
        label: "非公開",
        value: 0,
    }
]

export const PUBLISHING_DRAMA = [
    {
        label: "表示",
        value: 1,
    },
    {
        label: "非表示",
        value: 0,
    }
]

export const DELETE_STATUS = [
    {
        label: "有効",
        value: 1,
    }
]