<template>
  <div>
    <HEADER />
    <MENU />
    <div class="main">
      <h2 class="title">売上集計</h2>

      <el-form class="form-container" :model="formData">
        <div class="form-item" >
          <label>購入日</label>
            <div class="date-picker-w">
              <el-date-picker
                  v-model="formData.paymentAt"
                  type="daterange"
                  range-separator="~"
                  start-placeholder="開始日"
                  end-placeholder="終了日"
                  value-format="yyyy-MM-dd"
                  format="yyyy 年 M 月 d 日"
                  :editable="false"
              >
              </el-date-picker>
            </div>
        </div>
        <div class="form-item">
          <p>現在のアプリ登録者数: <span style="color: red; font-size: 20px;font-weight: bold">{{totalUser}}人</span></p>
        </div>
      </el-form>

      <div class="d-flex justify-content-center">
        <el-button type="primary" style="width: 178px;" :disabled="isLoading" @click="handleSearch">検索</el-button>
        <el-button type="primary" :disabled="isLoading" @click="handleDownloadCsv">ダウンロード（CSV）</el-button>
      </div>

      <BaseTable
        :data="transactions"
        :columns="columns"
        :pagination="pagination"
        :changePage="changePage"
      />
    </div>
  </div>
</template>
<script>
  import HEADER from "@/components/Header";
  import MENU from "@/components/MENU";
  import { api } from "@/api";
  import BaseDatePicker from "@/components/BaseDatePicker"
  import BaseTable from "@/components/BaseTable"

  const COLUMNS = [
    {
      name: "uid",
      label: "購入ID"
    },
    {
      name: "userUid",
      label: "ユーザーID"
    },
    {
      name: "paymentAt",
      label: "購入日時"
    },
    {
      name: "amount",
      label: "金額"
    }
  ]

  export default {
    name: "TotalSales",
    components: {
      HEADER,
      MENU,
      BaseDatePicker,
      BaseTable
    },
    data() {
      return {
        formData: {
          paymentAt: []
        },

        pagination: {
          currentPage: 1,
          pageSize: 15,
          total: 0,
          totalPage: 0
        },

        transactions: [],
        columns: COLUMNS,
        totalUser: 0,

        useDownloadCSV: this.$commonClass.useDownloadCSV()

      }
    },

    computed: {
      isLoading() {
        return this.$store.getters.isLoading
      }
    },

    methods: {
      async getTotalsales() {
        try {
          this.$store.dispatch('setLoading', true)

          let params = {
            per_page: this.pagination.pageSize,
            page: this.pagination.currentPage,
            ...(this.formData.paymentAt.length && { payment_at: this.formData.paymentAt } )
          };
          const { data, meta } = await api.listTransaction(params)

          this.transactions = this.transformTransaction(data);
          this.pagination.currentPage = meta.pagination.current_page
          this.pagination.total = meta.pagination.total
          this.pagination.pageSize = meta.pagination.per_page
          this.pagination.totalPage = meta.pagination.count

        } catch (error) {
            console.error("Error", error);
        } finally {
            this.$store.dispatch('setLoading', false)
        }
      },
      async getTotalUser() {
        try {
          this.$store.dispatch('setLoading', true)
          const { data } = await api.totalUser()
          this.totalUser = data.total ? data.total : 0
        } catch (error) {
          console.error("Error", error);
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      },
      changePage(value) {
        this.pagination.currentPage = value;
        this.getTotalsales();
      },
      
      async handleSearch() {
        await this.getTotalsales()
      },

      transformTransaction(data) {
        return data.map(item => ({
          uid: item.uid,
          userUid: item.user.uid,
          paymentAt: item.payment_at,
          amount: item.amount
        }))
      },

      async handleDownloadCsv() {
        this.$store.dispatch('setLoading', true)
        try {
          const { data } = await api.listTransactionExport({
            ...(this.formData.paymentAt.length && { payment_at: this.formData.paymentAt } )
          })

          this.useDownloadCSV.downloadCSVFile(this.useDownloadCSV.makeCSV(data))
        } catch (error) {
          console.log('Error', error)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      },
    },
    created() {
      this.getTotalsales()
      this.getTotalUser()
    }
  }
</script>
<style scoped>
  .form-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
    .form-item {
      width: 400px;
    }
  }
</style>
  