import Vue from "vue";
import Router from "vue-router";
import Login from "../views/Login";
import Home from "../views/Home";
import DramaList from "../views/drama/List";
import DramaStore from "../views/drama/Store";
import DramaUpdate from "../views/drama/Update";
import DramaShow from "../views/drama/Show";
import PointPackageList from "../views/point-package/List";
import PointPackageStore from "../views/point-package/Store";
import PointPackageUpdate from "../views/point-package/Update";
import TransactionList from "../views/transaction/List";
import EpisodeStore from "../views/episode/Store";
import EpisodeUpdate from "../views/episode/Update";
import TotalSales from "../views/total-sales";
import CommentList from "../views/comment/List";
import CommentUpdate from "../views/comment/Update";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
      meta: {
        title: "ログイン",
      },
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        title: "Home",
      },
    },
    {
      path: "/transactions",
      name: "transactions",
      component: TransactionList,
      meta: {
        title: "ポイント購入一覧",
      },
    },
    {
      path: "/drama",
      name: "drama-list",
      component: DramaList,
      meta: {
        title: "作品管理",
      },
    },
    {
      path: "/drama/store",
      name: "drama-store",
      component: DramaStore,
      meta: {
        title: "作品登録",
      },
    },
    {
      path: "/drama/show/:id",
      name: "drama-show",
      component: DramaShow,
      meta: {
        title: "編集",
      },
    },
    {
      path: "/drama/update/:id",
      name: "drama-update",
      component: DramaUpdate,
      meta: {
        title: "編集",
      },
    },
    {
      path: "/point-package/list",
      name: "point-package-list",
      component: PointPackageList,
      meta: {
        title: "ポイント設定​​",
      },
    },
    {
      path: "/point-package/store",
      name: "point-package-store",
      component: PointPackageStore,
      meta: {
        title: "ポイント設定​​",
      },
    },
    {
      path: "/point-package/update/:id",
      name: "point-package-update",
      component: PointPackageUpdate,
      meta: {
        title: "ポイント設定​​",
      },
    },
    {
      path: "/drama/:id/episode/store",
      name: "episode-store",
      component: EpisodeStore,
      meta: {
        title: "エピソード登録/編集​",
      },
    },
    {
      path: "/drama/:id/episode/:episodeId",
      name: "episode-update",
      component: EpisodeUpdate,
      meta:{
        title: "エピソード登録/編集​"
      }
    },
    {
      path:"/total-sales",
      name:"total-sales",
      component: TotalSales,
      meta:{
        title: "売上集計​"
      }
    },
    {
      path: "/comments",
      name: "comment-list",
      component: CommentList,
      meta: {
        title: "レビュー管理​",
      },
    },
    {
      path: "/comments/update/:id",
      name: "comment-update",
      component: CommentUpdate,
      meta: {
        title: "レビュー管理​",
      },
    },
  ],
});

export default router;
