<template>
    <div class="loading-icon">
        <img src="@/assets/image/loading.svg" alt="">
        <span>アップロード中​</span>
    </div>
</template>
<script>
    export default {
        name: "UploadLoading"
    }
</script>
<style scoped>
.loading-icon {
  width: 90px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loading-icon img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  animation: spin 1s linear infinite;
}

.loading-icon span {
  position: absolute;
  white-space: nowrap;
  font-size: 10px;
  top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>