<template>
  <div>
    <HEADER />
    <MENU />
    <div class="main">
      <h2 class="title">作品管理</h2>

      <div class="text-center">
        <router-link :to="{ name: 'drama-store' }">
          <el-button type="primary"> 新規登録(作品)</el-button>
        </router-link>
      </div>

      <el-table
        :header-cell-style="{
          background: '#efefef',
          color: '#212529',
          fontWeight: '500',
        }"
        :data="list"
        class="table table-bordered tablebox"
        style="font-size: 1.6rem"
        :cell-style="{ fontSize: '16px' }"
      >
        <el-table-column
          header-align="center"
          align="start"
          label="作品ID"
          width="150"
          prop="id"
        >
          <template #default="scope">
            <router-link
              :to="{ name: 'drama-update', params: { id: scope.row.id } }"
            >
              {{ scope.row.id }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="start"
          label="作品名"
          width="auto"
          prop="name"
        />
        <el-table-column
          header-align="center"
          align="start"
          label="登録エピソード数"
          width="auto"
          prop="episodes_total"
        >
        <template #default="scope">
            <router-link
              :to="{ name: 'drama-show', params: { id: scope.row.id } }"
            >
              {{ scope.row.episodes_total }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="start"
          label="視聴回数（合計）"
          width="auto"
          prop="views_total"
        />
        <el-table-column
            header-align="center"
            align="start"
            label="TOPカルーセル表示"
            width="auto"
            prop="is_publish_top"
        >
          <template #default="scope">
              {{ scope.row.is_publish_top ? '表示' : '非表示' }}
          </template>
        </el-table-column>
        <el-table-column
            header-align="center"
            align="start"
            label="表示順"
            width="auto"
            prop="order_no"
        />
        <el-table-column
            header-align="center"
            align="start"
            label="TOPに第1話表示"
            width="auto"
            prop="is_publish_episode_top"
        >
          <template #default="scope">
            {{ scope.row.is_publish_episode_top ? '表示' : '非表示' }}
          </template>
        </el-table-column>
        <el-table-column
            header-align="center"
            align="start"
            label="第1話表示順"
            width="auto"
            prop="episode_order_no"
        />
      </el-table>
      <PAGE
        :current="current"
        :pages="pages"
        :size="size"
        :total="total"
        @changePage="changePage"
      />
    </div>
  </div>
</template>
  
  <script>
import HEADER from "@/components/Header";
import MENU from "@/components/MENU";
import { api } from "@/api";
import PAGE from "@/components/page";

export default {
  name: "home",
  components: {
    HEADER,
    MENU,
    PAGE,
  },
  created() {
    this.getListDramas();
  },
  data() {
    return {
      list: [],
      pages: 0,
      total: 0,
      current: 1,
      size: 15,
    };
  },
  methods: {
    async getListDramas() {
      try {
        let params = {
          per_page: this.size,
          page: this.current,
        };

        const res = await api.listDrama(params);

        this.list = res.data;
        this.pages = res.meta.pagination.count;
        this.current = res.meta.pagination.current_page;
        this.total = res.meta.pagination.total;
        this.size = res.meta.pagination.per_page;
      } catch (e) {
        console.error("Error", e);
      }
    },
    changePage(value) {
      this.current = value;
      this.getListDramas();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}
</style>
