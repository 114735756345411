<template>
    <el-form :model="user" class="form-wrapper">
        <table class="table table-bordered tablebox-t5 textarea-detile">
            <tbody>
                <tr>
                <th width="210">
                    <div class="d-flex justify-content-between">
                    <div>
                        <span class="style_red">*</span>
                        <span>※ユーザーID</span>
                    </div>
                    </div>
                </th>
                <td>
                    <el-form-item label="" prop="uid">
                    <BaseInput
                        :value="user.uid"
                        keys="uid"
                        type="number"
                        class="pointer-events-none"
                    />
                    </el-form-item>
                </td>
                </tr>
                <tr>
                <th width="210">
                    <div class="d-flex justify-content-between">
                    <div>
                        <span class="style_red">*</span>
                        <span>※ポイント購入履歴</span>
                    </div>
                    </div>
                </th>
                <td>
                    <div class="table-scroll">
                        <BaseTable
                        :data="userTransactions"
                        :columns="columns"
                        :showPagination="false"
                        class="pointer-events-none"
                        />
                    </div>
                </td>
                </tr>
                <tr>
                <th width="210">※保有ポイント</th>
                <td>
                    <el-form-item prop="total_point">
                    <BaseInput
                        :value="user.total_point"
                        keys="total_point"
                        type="number"
                        class="pointer-events-none"
                    />
                    </el-form-item>
                </td>
                </tr>
                <tr>
                <th width="210">※性別</th>
                <td>
                    <el-form-item prop="gender">
                    <BaseInput
                        :value="getGenderName(user.gender)"
                        keys="gender"
                        class="pointer-events-none"
                    />
                    </el-form-item>
                </td>
                </tr>
                <tr>
                <th width="210">※誕生日</th>
                <td>
                    <el-form-item prop="date_of_birth">
                    <BaseInput
                        :value="user.date_of_birth"
                        keys="date_of_birth"
                        class="pointer-events-none"
                    />
                    </el-form-item>
                </td>
                </tr>
            </tbody>
        </table>
    </el-form>
</template>
<script>
    import BaseForm from "@/components/BaseForm";
    import BaseInput from "@/components/BaseInput";
    import BaseTable from "@/components/BaseTable";
    import { api } from "@/api";

    const COLUMNS = [
        {
            name: "transactionId",
            label: "購入ID"
        },
        {
            name: "paymentAt",
            label: "購入日時"
        },
        {
            name: "amount",
            label: "金額"
        }
    ]

    export default {
        name: "UserDetail",
        components: {
            BaseForm,
            BaseInput,
            BaseTable
        },
        props: {
            id: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                columns: COLUMNS,
                user: {
                    id: null,
                    total_point: null,
                    date_of_birth: null,
                    gender: null,
                    transactions_success: []
                },
                userTransactions: []
            }
        },
        watch: {
            id: {
                handler: function (id) {
                    if (id) this.handleGetUserDetail()
                },
                immediate: true
            }
        },

        methods: {
            async handleGetUserDetail() {
               
                if (!this.id) return
                try {
                    const { data } = await api.getDetailUser(this.id)
                    this.user = data

                    this.userTransactions = data.transactions_success.map(item => {
                        return {
                            transactionId: item.uid,
                            paymentAt: item.payment_at,
                            amount: item.amount
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            },

            getGenderName(gender) {
                if (gender === 0) {
                    return '男性';
                }

                if (gender === 1) {
                    return '女性';
                }

                return 'どちらでもない';
            },
        },
    }
</script>
<style scoped>
    .form-wrapper {
        max-height: 750px;
        height: auto;
        overflow-y: scroll;
    }
    .table-scroll {
        overflow-y: scroll;
        max-height: 350px;
        height: auto;
    }
    .pointer-events-none{
        pointer-events: none;
    }
</style>