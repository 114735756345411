import axios from 'axios';
/* eslint-disable */

import router from '../router'

axios.defaults.timeout = 100000;
axios.defaults.baseURL = process.env.VUE_APP_URL;

var token = null;

function has(arr, num) {
  var bool = false; //默认不在数组中
  for (var i = 0, len = arr.length; i < len; i++) { //遍历数组的值
    if (arr[i] === num) {
      bool = true;  //若存在某个值与改值相等，bool值为true
    }
  }
  return bool; //返回bool
}

//http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'
    token = localStorage.getItem("authToken");

    if (token != null) {
      // Set Authentication Header
      config.headers['Authorization'] = token
    }
    return config;
  }
);

//http response 拦截器

axios.interceptors.response.use(
  response => {
    if (response.data.errCode == 2) {
      router.push({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath }//从哪个页面跳转
      })
    }
    return response;
  },
  error => {
    if (error.response.status == 504) {
      router.push({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath }//从哪个页面跳转
      })
    }
    return Promise.reject(error)
  }
)



/**
 * 封装get请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url, options) {
  return new Promise((resolve, reject) => {
    axios.delete(url, options)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装download方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function download(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", res.headers['filename']);

      document.body.appendChild(link);

      link.click();
      resolve(res)
    }).catch((err) => {
      reject(err);
    })
  }
  )
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, config)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}. config = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}


/**
 * Set request header.
 */
export function setAuthToken(token) {
  token = token
}

/**
 * Set request header.
 */
export function getAuthToken() {
  return token
}
