<template>
  <div>
    <HEADER />
    <MENU />
    <div class="main">
      <div>
        <h2 class="title">レビュー管理​​</h2>
        <el-form :model="form" ref="myForm">
          <table class="table table-bordered tablebox-t5 textarea-detile">
            <tbody>
              <tr>
                <th width="160"><span class="style_red">*</span>レビューID​</th>
                <td>
                  <el-form-item label="">
                    <el-input
                      v-model="form.id"
                      class="px-4 py-3"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160"><span class="style_red">*</span>ユーザーID​</th>
                <td>
                  <el-form-item label="">
                    <el-input
                      v-model="form.user_uid"
                      class="px-4 py-3"
                      type="text"
                      placeholder=""
                      disabled
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">
                  <span class="style_red">*</span>レビュー内容​
                </th>
                <td>
                  <el-form-item>
                    <el-input
                      class="px-4 py-3"
                      v-model="form.content"
                      :rows="4"
                      type="textarea"
                      disabled
                    />
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160"><span class="style_red">*</span>ステータス​</th>
                <td>
                  <div class="px-4 py-3">
                    <el-radio-group v-model="form.is_publish">
                      <el-radio :label="true" class="mb-0">表示​</el-radio>
                      <el-radio :label="false" class="mb-0">非表示​</el-radio>
                    </el-radio-group>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form>
        <div class="btnbox text-center mt50 mb100">
          <button class="btn btn-red ml20" @click="submit">登録する​</button>
        </div>
      </div>
    </div>
  </div>
</template>
        
<script>
import HEADER from "@/components/Header";
import MENU from "@/components/MENU";
import { api } from "@/api";

export default {
  name: "comment-update",
  components: {
    HEADER,
    MENU,
  },
  props: {},
  data() {
    return {
      form: {
        id: "",
        user_uid: "",
        is_publish: "",
        content: "",
      },
      id: this.$router.history.current.params.id,
    };
  },
  created() {
    this.show();
  },
  methods: {
    async submit() {
      await api
        .updateComment(this.id, this.form)
        .then((response) => {
          this.$router.push({ name: "comment-list" });
        })
        .catch((error) => {
          if (error.response) {
            this.$message.error(
              Object.values(error.response.data.meta.errors)[0][0]
            );
          }
        });
    },
    async show() {
      try {
        const res = await api.showComment(this.id);

        this.form.id = res.data.id;
        this.form.user_uid = res.data.user_uid;
        this.form.is_publish = res.data.is_publish;
        this.form.content = res.data.content;
      } catch (e) {
        console.error("Error", e);
      }
    },
  },
};
</script>
        
<style scoped lang="scss">
.upload-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnbox {
  width: 100%;
}
</style>
  