<template>
  <div>
    <HEADER />
    <MENU />
    <div class="main">
      <h2 class="title">レビュー管理​</h2>

      <form class="">
        <div class="clear" style="display: inline-block">
          <div class="row-up-group">
            <div class="form-group fl mr20">
              <label>レビューID</label>
              <input
                type="text"
                class="form-control"
                v-model.trim="formSearch.comment_id"
                placeholder=""
              />
            </div>
            <div class="form-group fl mr20">
              <label>ユーザーID</label>
              <input
                type="text"
                class="form-control"
                v-model.trim="formSearch.user_uid"
                placeholder=""
              />
            </div>
            <div class="form-group fl mr20">
              <label>投稿日</label>
              <div class="date-picker-w">
                <el-date-picker
                    v-model="formSearch.created_at"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="開始日"
                    end-placeholder="終了日"
                    value-format="yyyy-MM-dd"
                    format="yyyy 年 M 月 d 日"
                    :editable="false"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="form-group fl mr20">
              <label class="opacity-0">.</label>
              <div class="date-picker">
                <el-checkbox
                  v-model="formSearch.is_report_comment"
                  class="mb-0 checkbox-custom"
                  size="medium"
                  label="報告フラグ​"
                  :true-label="'true'"
                  :false-label="'false'"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="text-center">
        <button type="button" class="btn btn-red ml20" @click="search()">
          検索
        </button>
      </div>

      <el-table
        :header-cell-style="{
          background: '#efefef',
          color: '#212529',
          fontWeight: '500',
        }"
        :data="list"
        class="table table-bordered tablebox"
        style="font-size: 1.6rem"
        :cell-style="{ fontSize: '16px' }"
      >
        <el-table-column
          header-align="center"
          align="center"
          label="レビューID"
          width="150"
          prop="id"
        >
          <template #default="scope">
            <router-link
              :to="{ name: 'comment-update', params: { id: scope.row.id } }"
            >
              {{ scope.row.id }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="start"
          label="ユーザーID"
          width="auto"
          prop="user_uid"
        />
        <el-table-column
          header-align="center"
          align="start"
          label="投稿日時"
          width="auto"
          prop="created_at"
        >
          <template #default="scope">
            {{ formatDate(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="start"
          label="報告"
          width="auto"
          prop="use_comment_flag"
        >
          <template #default="scope">
            <div v-if="scope.row.use_comment_flag" class="label-free">あり</div>
            <div v-else>なし</div>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="start"
          label="ステータス​"
          width="auto"
          prop="is_publish"
        >
          <template #default="scope">
            <div v-if="scope.row.is_publish">表示</div>
            <div v-else class="label-free">非表示</div>
          </template>
        </el-table-column>
      </el-table>
      <PAGE
        :current="current"
        :pages="pages"
        :size="size"
        :total="total"
        @changePage="changePage"
      />
    </div>
  </div>
</template>
    
    <script>
import HEADER from "@/components/Header";
import MENU from "@/components/MENU";
import { api } from "@/api";
import PAGE from "@/components/page";
import moment from "moment";

export default {
  name: "home",
  components: {
    HEADER,
    MENU,
    PAGE,
  },
  created() {
    this.getListComments();
  },
  data() {
    return {
      list: [],
      pages: 0,
      total: 0,
      current: 1,
      size: 15,
      formSearch: {
        comment_id: null,
        uid: null,
        created_at: null,
        is_report_comment: false,
      },
    };
  },
  methods: {
    async getListComments() {
      try {
        let params = {
          per_page: this.size,
          page: this.current,
          ...this.formSearch,
        };

        const res = await api.listComment(params);

        this.list = res.data;
        this.pages = res.meta.pagination.count;
        this.current = res.meta.pagination.current_page;
        this.total = res.meta.pagination.total;
        this.size = res.meta.pagination.per_page;
      } catch (e) {
        console.error("Error", e);
      }
    },
    changePage(value) {
      this.current = value;
      this.getListComments();
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    search() {
      this.getListComments();
    },
  },
};
</script>
    
<style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}

.label-free {
  color: #e9546b;
}

.opacity-0 {
  opacity: 0;
}
.checkbox-custom::v-deep {
  .el-checkbox__inner {
    width: 4rem !important;
    height: 4rem !important;

    &::after {
      height: 18px;
      left: 14px;
      top: 6px;
      width: 9px;
    }
  }
}
</style>
  