<template>
  <div id="menu">
    <div class="navbox">
      <ul class="clear">
        <li style="cursor: pointer">ホーム <span></span></li>
      </ul>
    </div>
    <div class="sidebar">
      <ul>
        <li>
          <span>
            <span class="">ユーザー管理</span>
          </span>
          <ul>
            <li>
              <router-link exact :to="{ name: 'transactions' }"> ポイント購入一覧</router-link>
            </li>
            <li>
              <router-link exact :to="{ name: 'total-sales' }"> 売上集計​ </router-link>
            </li>
          </ul>
        </li>
        <li class="">
          <router-link exact :to="{ name: 'drama-list' }" :class="{ 'router-link-active': dramaActiveName.includes($route.name) }" > 作品管理</router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'comment-list' }"> レビュー管理 </router-link>
        </li>
        <li class="">
          <router-link exact :to="{ name: 'point-package-list' }"> ポイント管理 </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MENU",
  data() {
    return {
      dramaActiveName: ["drama-store", "drama-show", "drama-update", "episode-store", "episode-update"]
    };
  },
};
</script>

<style scoped>
  a.noline:hover {
    text-decoration: none;
  }

  .router-link-exact-active, .router-link-active {
    color: red;
  }
</style>
