<template>
  <div>
    <HEADER />
    <MENU />
    <div class="main">
      <div>
        <h2 class="title">ポイント設定​</h2>
        <el-form :model="form" ref="myForm" :rules="formRules">
          <table class="table table-bordered tablebox-t5 textarea-detile">
            <tbody>
              <tr>
                <th width="210">
                  <div class="d-flex justify-content-between">
                    <div>
                      <span class="style_red">*</span>
                      <span>ポイント</span>
                    </div>
                    <span>※半角数字​</span>
                  </div>
                </th>
                <td>
                  <el-form-item label="" prop="points">
                    <el-input
                      v-model="form.points"
                      type="number"
                      @keypress.native="(event) => handleOnKeyPress(event)"
                      @keydown.native="(event) => handleOnKeyDown(event)"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="210">
                  <div class="d-flex justify-content-between">
                    <div>
                      <span class="style_red">*</span>
                      <span>通常金額(円)​</span>
                    </div>
                    <span>※半角数字​</span>
                  </div>
                </th>
                <td>
                  <el-form-item prop="amount">
                    <el-input
                      v-model="form.amount"
                      type="number"
                      @keypress.native="(event) => handleOnKeyPress(event)"
                      @keydown.native="(event) => handleOnKeyDown(event)"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="210">割引金額(円)​</th>
                <td>
                  <el-form-item prop="reduced_amount">
                    <el-input
                      v-model="form.reduced_amount"
                      type="number"
                      @keypress.native="(event) => handleOnKeyPress(event)"
                      @keydown.native="(event) => handleOnKeyDown(event)"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form>
        <div class="btnbox text-center mt50 mb100">
          <button class="btn btn-red ml20" @click="submit">登録する​</button>
        </div>
      </div>
    </div>
  </div>
</template>
        
  <script>
import HEADER from "@/components/Header";
import MENU from "@/components/MENU";
import { api } from "@/api";

export default {
  name: "point-package-store",
  components: {
    HEADER,
    MENU,
  },
  data() {
    return {
      form: {
        points: null,
        amount: null,
        reduced_amount: null,
      },
      formRules: {
        points: [
          {
            required: true,
            message: "ポイントを入力してください。",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "通常金額(円)を入力してください。",
            trigger: "blur",
          },
        ],
        reduced_amount: [
          { validator: this.validateReducedAmountRef, trigger: "blur" },
        ],
      },
      width: 0,
      height: 0,
      charaterNotEnter: ["-", "e", "+", "."],
      isValidate: false,
    };
  },
  methods: {
    async submit() {
      this.checkValidate();

      if (!this.isValidate) {
        await api
          .storePointPackage(this.form)
          .then(() => {
            this.$router.push({ name: "point-package-list" });
          })
          .catch((error) => {
            if (error.response) {
              this.$message.error(
                Object.values(error.response.data.meta.errors)[0][0]
              );
            }
          });
      }
    },
    checkValidate() {
      this.$refs.myForm.validate((valid) => {
        this.isValidate = !valid;
      });
    },
    handleOnKeyPress(event) {
      if (this.charaterNotEnter.includes(event.key)) event.preventDefault();
    },
    handleOnKeyDown(event) {
      if (event.key === 'ArrowDown' && event.target.value == 0) event.preventDefault();
    },
    validateReducedAmountRef(rule, value, callback) {
      if (Number(this.form.amount) < Number(this.form.reduced_amount)) {
        callback(
          new Error("割引金額(円)は通常金額(円)より小さくなければなりません。")
        );
      } else {
        callback();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.upload-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnbox {
  width: 100%;
}

.el-form-item {
  margin-bottom: 0 !important;
  padding: 1.2rem;
}
</style>

  