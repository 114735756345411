<template>
    <div>
        <el-table 
            :data="data"
            :header-cell-style="{
                background: '#efefef',
                color: '#212529',
                fontWeight: '500',
                textAlign: 'center'
            }"
            class="table table-bordered tablebox"
        >
            <el-table-column
                v-for="(column, index) in columns" 
                :prop="column.name" 
                :label="column.label" 
                :key="index">
            </el-table-column>
        </el-table>
        
        <PAGE
            v-if="showPagination"
            :current="pagination.currentPage"
            :pages="pagination.totalPage"
            :size="pagination.pageSize"
            :total="pagination.total"
            @changePage="changePage"
        />
    </div>
    
</template>
<script>

    import PAGE from '@/components/page';
    export default {
        name: "BaseTable",
        components: {
            PAGE
        },
        props: {
            data: {
                type: Array,
                default: () => []
            },
            columns: {
                type: Array,
                default: () => []
            },
            pagination: {
                type: Object,
                required: false,
                default: () => {}
            },
            changePage: {
                type: Function,
                default: () => {}
            },
            showPagination: {
                type: Boolean,
                default: true
            }
            
        },
    }
</script>