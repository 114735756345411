<template>
    <el-dialog
        :visible=visible
        top="0"
        width="70%"
        @close="close"
        v-bind="$attrs">
        <slot></slot>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            close: {
                type: Function,
                default: () => {}
            }
        }
    }
</script>
<style scoped>

    .el-dialog__wrapper {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

    }
</style>