<template>
  <div id="app">
    <div class="mt-5">
      <router-view />
      <footer id="footer" class="bg-light text-center p-3">
        <p>© 2020 FOUR GLOBE Inc.</p>
      </footer>
    </div>
  </div>
</template>
<script>
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/style.css";
import "@/assets/css/styleCss.css";
export default {created() {
    this.getProfile()
  },
  computed: {
    profileUrl: function () {
      return `${process.env.VUE_APP_URL}/profile`;
    },
  },
  methods: {
    getProfile() {
      const token = localStorage.getItem("authToken");
      if (!token) {
        return this.$router.push({ name: 'login'})
      }
      this.$get(this.profileUrl, {}).then(res => {
        this.$store.commit("SET_ADMIN", res.data);
      }).catch(() => {
        localStorage.removeItem("authToken");
        return this.$router.push({ name: 'login'})
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1060px) {
  ::deep .el-table .cell {
    line-height: 20px;
    padding: 0 5px;
  }
  ::deep .el-input__inner {
    height: 34px;
    line-height: 34px;
  }
  ::deep .el-input__icon {
    line-height: 34px;
  }
  ::deep .el-dialog__body {
    padding: 15px 20px 30px;
  }
}

@media only screen and (max-width: 500px) {
  .el-message-box__btns {
    text-align: center !important;
  }
}
</style>
