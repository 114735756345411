<template>
  <div>
    <HEADER />
    <MENU />
    <div class="main">
      <div>
        <h2 class="title">エピソード登録/編集​</h2>
        <el-form :model="form" ref="myForm">
          <table class="table table-bordered tablebox-t5 textarea-detile">
            <tbody>
              <tr>
                <th width="160"><span class="style_red">*</span>作品ID​</th>
                <td>
                  <el-form-item label="" prop="nameRef">
                    <el-input
                      v-model="form.id"
                      class="px-4 py-3"
                      type="text"
                      placeholder="XXXXXXXX"
                      disabled
                    />
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">
                  <span class="style_red">*</span>作品タイトル
                </th>
                <td>
                  <el-form-item label="">
                    <el-input
                      v-model="form.name"
                      class="px-4 py-3"
                      type="text"
                      placeholder="作品タイトルを入力して下さい。​"
                      disabled
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160"><span class="style_red">*</span>ストーリー​</th>
                <td>
                  <div class="text-center px-4 py-3">
                    <router-link :to="`/drama/${id}/episode/store`">
                      <el-button type="primary">エピソード登録​</el-button>
                    </router-link>
                    <el-table
                      :header-cell-style="{
                        background: '#efefef',
                        color: '#212529',
                        fontWeight: '500',
                      }"
                      :data="listEpisodes"
                      class="table table-bordered tablebox"
                      style="font-size: 1.6rem"
                      :cell-style="{ fontSize: '16px' }"
                    >
                      <el-table-column
                        header-align="center"
                        align="center"
                        label="作品ID​"
                        width="150"
                        prop="id"
                      >
                        <template #default="scope">
                          <router-link
                            :to="`/drama/${id}/episode/${scope.row.id}`"
                          >
                            {{ scope.row.id }}
                          </router-link>
                        </template>
                      </el-table-column>
                      <el-table-column
                        header-align="center"
                        align="center"
                        label="エピソードNo.​"
                        width="auto"
                        prop="order_no"
                      />
                      <el-table-column
                        header-align="start"
                        align="center"
                        label="エピソードタイトル​​"
                        width="auto"
                        prop="title"
                      />
                      <el-table-column
                        header-align="center"
                        align="center"
                        label="時間​​"
                        width="auto"
                        prop="time"
                      >
                        <template #default="scope">
                          {{ convertSecondsToHHMMSS(scope.row.time) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        header-align="center"
                        align="center"
                        label="ポイント設定​"
                        width="auto"
                        prop="points"
                      >
                        <template #default="scope">
                          <div v-if="scope.row.points === 0" class="label-free">
                            無料
                          </div>
                          <div v-else>
                            {{ scope.row.points }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form>
        <div class="btnbox text-center mt50 mb100">
          <router-link
            :to="{
              name: 'drama-update',
              params: { id: id },
            }"
          >
            <button class="btn btn-red ml20">更新する​</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import HEADER from "@/components/Header";
import MENU from "@/components/MENU";
import { api } from "@/api";
import axios from "axios";

export default {
  name: "drama",
  components: {
    HEADER,
    MENU,
  },
  props: {},
  data() {
    return {
      form: {
        id: "",
        name: "",
        story: "",
        image: null,
      },
      id: this.$router.history.current.params.id,
      width: 0,
      height: 0,
      file: null,
      listEpisodes: [],
    };
  },
  watch: {
    "form.image"() {
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.form.image);

      reader.onload = function (event) {
        let img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          that.width = this.width;
          that.height = this.height;
        };
      };
    },
  },
  computed: {
    url: function () {
      return `${process.env.VUE_APP_URL}/dramas/${this.id}`;
    },
  },
  created() {
    this.show();
  },
  methods: {
    async submit() {
      try {
        let formData = this.formData();

        await axios.post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("authToken"),
          },
        });

        this.$router.push({ name: "drama-list" });
      } catch (error) {
        console.error("Error", error);
      }
    },
    beforeUpload(file) {
      this.form.image = file;

      return true;
    },
    async show() {
      try {
        const res = await api.showDrama(this.id);

        this.form.id = res.data.id;
        this.form.name = res.data.name;
        this.form.story = res.data.story;
        this.file = res.data.image;

        this.listEpisodes = res.data.episodes;

        this.setPropertyImage();
      } catch (e) {
        console.error("Error", e);
      }
    },
    formData() {
      let formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("story", this.form.story);
      formData.append("image", this.form.image);
      formData.append("_method", "PUT");

      return formData;
    },
    setPropertyImage() {
      let that = this;
      let img = new Image();
      img.src = this.file;
      img.onload = function () {
        that.width = this.width;
        that.height = this.height;
      };
    },
    convertSecondsToHHMMSS(seconds) {
      let hour = Math.floor(seconds / 3600);
      let minute = Math.floor((seconds % 3600) / 60);
      let second = seconds % 60;

      return `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}:${second.toString().padStart(2, "0")}`;
    },
  },
};
</script>
      
<style scoped lang="scss">
.upload-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnbox {
  width: 100%;
}

.label-free {
  color: #e9546b;
}
</style>
