import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// import { Auth0Plugin } from "./auth";
import HighlightJs from "./directives/highlight";
import { post, put, get, download, setAuthToken, getAuthToken, del } from "./utils/http";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLink,
  faUser,
  faPowerOff,
faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { PAY_METHOD_LIST, ORDER_TYPE } from "@/constants/processingStatus";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/ja";
import locale from "element-ui/lib/locale";
import commonClass from "@/utils/common.js"; // eslint-disable-line
import authClass from "@/utils/auth.js";
import history from "connect-history-api-fallback"; // eslint-disable-line
import VueRouter from "vue-router";
import Print from "vue-print-nb";
import moment from "moment";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import store from "./store";

Vue.use(Print);
locale.use(lang);
Vue.use(ElementUI);
Vue.config.productionTip = false;

// Base Axios
import { $request } from "@/plugins/axios.js";
Vue.prototype.$request = $request;

//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$del = del;
Vue.prototype.$put = put;

Vue.prototype.$download = download;
Vue.prototype.$setAuthToken = setAuthToken;
Vue.prototype.$getAuthToken = getAuthToken;
Vue.prototype.$commonClass = new commonClass();
Vue.prototype.$auth = new authClass();

library.add(faLink, faUser, faPowerOff, faLanguage);
Vue.component("font-awesome-icon", FontAwesomeIcon);

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("authToken");
  if (to.name !== 'login' && !token) {
    next({ path: "/" });
  }
  if (to.name === 'login' && token) {
    next({path: '/home'})
  }

  next()
});

router.afterEach(async (to) => {
  // TODO: counter measure for F5 case should be considered.
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
