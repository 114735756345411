<template>
  <el-radio-group :value="value" @input="changeRadio">
    <el-radio v-for="option in options" :key="option.value" :label="option.value">
      {{ option.label }}
    </el-radio>
  </el-radio-group> 
</template>

<script>
export default {
  name: "BaseRadio",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number | Boolean,
      default: 0
    },
    keys: {
      type: String,
      default: ""
    }
  },
  methods: {
    changeRadio(value) {
      this.$emit("change-radio", this.keys, value);
    },
  },
};
</script>